export class newBigBanScreenTs {
    // 图表高度设置
    fontSize(res: any) {
        let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        if (!clientWidth) return;
        let fontSize = 100 * (clientWidth / 1920);
        return res * fontSize;
    }



    public TitleRightDateObj: any = {  //大屏右侧事件对象
        firstTime: "",
        lastTime: ""
    }


    public LeftEchartsTwo: any = {   //左下方 与 右下方的配置项
        color: ['#009EFE', '#8261FF', '#0050E8'],
        legend: {
            zlevel: -1,
            color: 'white',
            // right: this.fontSize(0.2),
            right: 20,
            top: 20,
            itemHeight: this.fontSize(0.1), // 修改icon图形大小
            itemWidth: this.fontSize(0.1),
            itemGap: this.fontSize(0.05), // 修改间距
            // align: 'right',
            textStyle: {
                fontSize: this.fontSize(0.14),
                color: "#fff",
                padding: [0, 0, 0, 0], // 修改文字和图标距离
            },
        },
        // 设置初始能显示几个柱状图
        dataZoom: [
            { // 第一个 dataZoom 组件
                type: 'inside',
                disable: true,
            },
        ],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'none', // 悬浮上去，柱状图会有阴影样式的设置
            },
            textStyle: {
                fontSize: this.fontSize(0.12),		// 鼠标悬浮显示文字字体大小
            },
        },
        grid: {
            left: '8%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            minInterval: 1,
            axisLabel: {
                color: "#93B5FF",
                interval: 0,
                fontSize: this.fontSize(0.12),
            },
            axisTick: {			// 坐标轴线的刻度
                show: false
            },
            axisLine: {			// x轴的坐标轴线
                show: true,
                lineStyle: {
                    color: 'rgba(0, 131, 255, 1)'
                }
            },
            splitLine: {
                // show 设置y轴背景的刻度线为 隐藏  隐藏背景网格
                show: false,
            },
        },
        yAxis: {
            name: "单位/头",
            type: 'category',
            splitLine: {
                // show 设置y轴背景的刻度线为 隐藏  隐藏背景网格
                show: false,
            },
            nameTextStyle: {
                color: "rgba(147, 181, 255, 1)",
                fontSize: this.fontSize(0.12),		// 单位(头) 的字体大小
                align: 'right'
            },
            axisLabel: {
                fontSize: this.fontSize(0.12),		// y轴刻度尺 （100，200，300） 的字体大小
                color: "rgba(147, 181, 255, 1)",
                formatter: (value: any) => {
                    // 设置x轴 名称过长 问题：超过4个字省略 ...
                    if (value.length > 4) {
                        return value.substr(0, 4) + "...";
                    } else {
                        return value.substr(0, 5);
                    }
                },
            },
            axisTick: {			// 坐标轴线的刻度
                show: false,  //隐藏刻度
                inside: true, // 是否朝内，默认朝外
                lineStyle: {
                    color: '#133C7B'
                }
            },
            axisLine: {			// y轴的坐标轴线
                show: true,
                lineStyle: {
                    color: 'rgba(0, 131, 255, 1)'
                }
            },
            data: []
            // data: ['中国银行', '农业银行', '邮政银行d', '光明银行', '微商银行', '工商银行', '大大银行','中国河南省郑州市金水区建设银行','猜猜银行' , '棒子银行']   //替换为银行名称
        },
        textStyle: {
            color: '#fff',
            // fontSize: 8,
        },
        series: [
            {
                name: '猪',
                type: 'bar',
                stack: 'total',
                barWidth: this.fontSize(0.2),
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                // data: [120, 132, 101, 134, 90, 230, 210, 390, 330, 320]
                data: []
            },
            {
                name: '牛',
                type: 'bar',
                stack: 'total',
                barWidth: this.fontSize(0.2),
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                // data: [320, 302, 301, 334, 390, 330, 320, 390, 330, 320]  //银行数据
                data: []
            },
            {
                name: '羊',
                type: 'bar',
                stack: 'total',
                barWidth: this.fontSize(0.2),
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                // data: [220, 182, 191, 234, 290, 330, 310, 390, 330, 320]
                data: []
            },
        ]
    }

    public RightEchartsTwo: any = {   //左下方 与 右下方的配置项
        color: ["#039DFF", "#084CF4"],
        legend: {
            zlevel: -1,
            color: 'white',
            // right: this.fontSize(0.2),
            right: 20,
            top: 20,
            itemHeight: this.fontSize(0.1), // 修改icon图形大小
            itemWidth: this.fontSize(0.1),
            itemGap: this.fontSize(0.05), // 修改间距
            // align: 'right',
            textStyle: {
                fontSize: this.fontSize(0.14),
                color: "#fff",
                padding: [0, 0, 0, 0], // 修改文字和图标距离
            },
        },
        // 设置初始能显示几个柱状图
        dataZoom: [
            { // 第一个 dataZoom 组件
                type: 'inside',
                disable: true,
            },
        ],
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'none', // 悬浮上去，柱状图会有阴影样式的设置
            },
            textStyle: {
                fontSize: this.fontSize(0.12),		// 鼠标悬浮显示文字字体大小
            },
        },
        grid: {
            left: '8%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'value',
            minInterval: 1,
            axisLabel: {
                color: "#93B5FF",
                interval: 0,
                fontSize: this.fontSize(0.12),
            },
            axisTick: {			// 坐标轴线的刻度
                show: false
            },
            axisLine: {			// x轴的坐标轴线
                show: true,
                lineStyle: {
                    color: 'rgba(0, 131, 255, 1)'
                }
            },
            splitLine: {
                // show 设置y轴背景的刻度线为 隐藏  隐藏背景网格
                show: false,
            },
        },
        yAxis: {
            name: "单位/家",
            type: 'category',
            splitLine: {
                // show 设置y轴背景的刻度线为 隐藏  隐藏背景网格
                show: false,
            },
            nameTextStyle: {
                color: "rgba(147, 181, 255, 1)",
                fontSize: this.fontSize(0.12),		// 单位(头) 的字体大小
                align: 'right'
            },
            axisLabel: {
                fontSize: this.fontSize(0.12),		// y轴刻度尺 （100，200，300） 的字体大小
                color: "rgba(147, 181, 255, 1)",
                formatter: (value: any) => {
                    // 设置x轴 名称过长 问题：超过4个字省略 ...
                    if (value.length > 4) {
                        return value.substr(0, 4) + "...";
                    } else {
                        return value.substr(0, 5);
                    }
                },
            },
            axisTick: {			// 坐标轴线的刻度
                show: false,  //隐藏刻度
                inside: true, // 是否朝内，默认朝外
                lineStyle: {
                    color: '#133C7B'
                }
            },
            axisLine: {			// y轴的坐标轴线
                show: true,
                lineStyle: {
                    color: 'rgba(0, 131, 255, 1)'
                }
            },
            data: []
            // data: ['中国银行', '农业银行', '邮政银行d', '光明银行', '微商银行', '工商银行', '大大银行','中国河南省郑州市金水区建设银行','猜猜银行' , '棒子银行']   //替换为银行名称
        },
        textStyle: {
            color: '#fff',
            // fontSize: 8,
        },
        series: [
            {
                name: '养殖企业',
                type: 'bar',
                stack: 'total',
                barWidth: this.fontSize(0.2),
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: []
            },
            {
                name: '养殖农户',
                type: 'bar',
                stack: 'total',
                barWidth: this.fontSize(0.2),
                label: {
                    show: true
                },
                emphasis: {
                    focus: 'series'
                },
                data: []
            },
        ]
    }



    public LeftEchartsOne: any = {  //左上方配置项

        tooltip: {
            trigger: "axis",
            formatter: "{b}: {c}元",
            axisPointer: {
                type: "shadow",
            },
        },
        legend: {
            zlevel: -1,
            color: 'white',
            // right: this.fontSize(0.2),
            right: 20,
            top: 20,
            itemHeight: this.fontSize(0.1), // 修改icon图形大小
            itemWidth: this.fontSize(0.1),
            itemGap: this.fontSize(0.05), // 修改间距
            // align: 'right',
            textStyle: {
                fontSize: this.fontSize(0.14),
                color: "#fff",
                padding: [0, 0, 0, 0], // 修改文字和图标距离
            },
        },
        xAxis: {
            type: "category",
            // data:  ['中国银行', '农业银行', '邮政银行d', '光明银行', '微商银行', '工商银行', '大大银行','中国河南省郑州市金水区建设银行','猜猜银行' , '棒子银行'] ,  //替换为银行名称
            data: [],
            axisLabel: {
                color: "#93B5FF",
                interval: 0,
                fontSize: this.fontSize(0.12),
                rotate: 40,  //倾斜
                formatter: (value: any) => {
                    // 设置x轴 名称过长 问题：超过4个字省略 ...
                    if (value.length > 3) {
                        return value.substr(0, 4) + "...";
                    } else {
                        return value.substr(0, 5);
                    }
                },
            },
            axisLine: {
                lineStyle: {
                    color: "rgba(0, 131, 255, 1)",
                },
            },
            axisTick: {
                show: false,
            },
        },
        yAxis: {
            name: "单位:元",
            type: "value",
            axisLabel: {
                color: "rgba(147, 181, 255, 1)",
            },
            splitLine: {
                show: false,
            },
            axisTick: {			// 坐标轴线的刻度
                show: false,  //隐藏刻度
                inside: true, // 是否朝内，默认朝外
                lineStyle: {
                    color: '#133C7B'
                }
            },
            nameLocation: "end",
            minInterval: 1,
            splitNumber: 2,
            nameTextStyle: {
                fontSize: this.fontSize(0.12),
                align: "right",
                color: "rgba(147, 181, 255, 1)",
                // verticalAlign: "top",
                lineHeight: this.fontSize(0.2),
            },
            axisLine: {
                lineStyle: {
                    color: "rgba(0, 131, 255, 1)",
                },
            },
        },
        grid: {
            left: '8%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        animationDuration: 5000,

        series: [
            {
                cursor: "default",

                // data: [1,2,3,4,5,6, 99,32,58,54,80,6,63,3,5],
                data: [],
                type: "bar",
                // showBackground: true,
                backgroundStyle: {
                    color: "rgba(220, 220, 220, 0.8)",
                },
                itemStyle: {
                    color: "#f0a438",
                },
                barWidth: 8,
            },
        ],

    }

    public RightEchartsOne: any = { //右上方配置项
        tooltip: {
            trigger: "axis",
            axisPointer: {
                // 坐标轴指示器，坐标轴触发有效
                type: "none", // 默认为直线，可选为：'line' | 'shadow'
            },
            formatter: "{b}：{c}元",
        },
        grid: {
            left: '8%',
            right: '4%',
            bottom: '3%',
            containLabel: true
        },
        xAxis: [
            {
                type: "category",
                data: [],
                // data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
                boundaryGap: false,
                axisLine: {
                    lineStyle: {
                        color: "rgba(0, 131, 255, 1)",
                    },
                },
                axisLabel: {
                    fontSize: this.fontSize(0.14),
                    color: "#93B5FF",
                    interval: 0,
                    fontFamily: "s-r",
                },
                axisTick: {
                    show: false,
                },
            },
        ],
        yAxis: [
            {
                name: "单位:元",
                nameLocation: "end",
                type: "value",
                axisLine: {
                    lineStyle: {
                        color: "rgba(0, 131, 255, 1)",
                    },
                },
                axisLabel: {
                    fontSize: this.fontSize(0.14),

                    color: "#93B5FF",
                },
                minInterval: 1,

                nameTextStyle: {
                    fontSize: this.fontSize(0.12),
                    align: "right",
                    color: "rgba(147, 181, 255, 1)",
                    // verticalAlign: "top",
                    lineHeight: this.fontSize(0.3),
                },
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                    inside: true,
                    length: 3,
                    lineStyle: {
                        color: " #C27F00",
                    },
                },
            },
        ],
        series: [
            {
                name: "牲畜销售数量",
                type: "line",
                // barWidth: 10,
                stack: "非法离开",
                // data: [120, 132, 101, 134, 90, 230, 210]
                //   data: [1,2,3,12,3,12,3,12,31,2,312,3,123],
                data: [],
                cursor: "default",
                areaStyle: {
                    opacity: 0.3,
                    color: {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                            {
                                offset: 0,
                                color: "#C27F00", // 0% 处的颜色
                            },
                            {
                                offset: 1,
                                color: "#3F3236", // 100% 处的颜色
                            },
                        ],
                        global: false, // 缺省为 false
                    },
                },
            },
        ],
        animationDuration: 5000,
        color: [' #C27F00']
    }

}