














import {Component , Watch , Prop , Vue} from 'vue-property-decorator'
import echarts from 'echarts';
import {newBigBanScreenTs} from './newBigBanScreenTs'
import {loansQuantity315} from '@/api/index' 
@Component({})
export default class NewBankLeftOneVue extends Vue{

    private LeftOneDataList:any[] = []
    private option:any = new newBigBanScreenTs().LeftEchartsOne

    private initEcharts(xdata:any[] , ydata:any[]){
        let chartDom:any = document.getElementById('echartsLeftOne');
        let myChart:any = echarts.init(chartDom);
        myChart.clear()
        this.option.xAxis.data = xdata  //赋值
        this.option.series[0].data = ydata  //赋值
        this.option && myChart.setOption(this.option);
        // 页面监控宽度的变化
        window.addEventListener("resize", function () {
            myChart.resize();
        });

    }

    private HandlerData(data:any[]){
        let xdata:any[] = []
        let ydata:any[] = []
        data.forEach((item:any)=>{
            xdata.push(item.bankName)
            ydata.push(item.amount)
        })

        let xxdata:any[] = []
        let yydata:any[] = []
        xxdata = xdata.splice(this.initPage,this.PageSize)
        yydata = ydata.splice(this.initPage,this.PageSize)
        this.initEcharts(xxdata,yydata)
    }

    private getEchartData(bankId:string){  //通过父组件ref 调用此组件的实例内容
        loansQuantity315(bankId,(data:any) =>{
            this.LeftOneDataList = data.data
            if(this.LeftOneDataList.length > 10){
                this.NextBtnFlag = true
            }else{
                this.NextBtnFlag = false
            }
            this.HandlerData(this.LeftOneDataList)
        })
        // setTimeout(()=>{
            // this.LeftOneDataList = new newBigBanScreenTs().testLeftOneData
        //     if(this.LeftOneDataList.length > 10){
        //         this.NextBtnFlag = true
        //     }else{
        //         this.NextBtnFlag = false
        //     }
        //     this.HandlerData(this.LeftOneDataList)
        // },500)
    }

    private initPage: number = 0  //第一次从0开始  从下标0  开始截取  每次截取10个  
    private PageSize: number = 10;  // 每次翻页 的数量
    //关于 翻页

    private NextPage(){
        //先判断 是否是最后一页 如果是最后一页 return
        if( (this.initPage + 10) >= this.LeftOneDataList.length ){  //判断+10 是否 大于 等于 数组的最大长度
            //走另外一个函数
            return
        }else{
            this.initPage += 10
            this.HandlerData(this.LeftOneDataList)
        }
    }

    private PrePage(){
        //先判断
        if((this.initPage - 10) <= 0){
            this.initPage = 0
            this.HandlerData(this.LeftOneDataList)
            return
        }else{
            this.initPage -= 10
            this.HandlerData(this.LeftOneDataList)
        }
        
    }

    private NextBtnFlag:boolean = true
    @Watch("initPage")
    private ChangeInitPage(newVal:any){
        newVal + 10 >= this.LeftOneDataList.length ? this.NextBtnFlag = false : this.NextBtnFlag = true
    }

            
    //进入页面  请求数据
    //获取数据后  就将数据 分成多个数组
}
