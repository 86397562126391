







































































































import { Component, Vue } from "vue-property-decorator";
import {
    bigbankList315,
    bigbankMap315,
    bankmapStatistics315,
    banktotalLoan315,
    statisticsTotal315,
} from "@/api/index";
import { newBigBanScreenTs } from "./components/newBigBanScreenTs";
import NewBigScreenTitleVue from "./components/NewBigScreenTitleVue.vue";
import BigScreenNavVue from "@/components/BasicConponents/BigScreenNavVue.vue";
import NewBankLeftTwoVue from "@/views/largeScreen/NewBigBankScreen/components/NewBankLeftTwoVue.vue";
import NewBankRightTwoVue from "@/views/largeScreen/NewBigBankScreen/components/NewBankRightTwoVue.vue";
import NewBankLeftOneVue from "@/views/largeScreen/NewBigBankScreen/components/NewBankLeftOneVue.vue";
import NewBankRightoneVue from "@/views/largeScreen/NewBigBankScreen/components/NewBankRightoneVue.vue";
import NewBankCenterTopVue from "@/views/largeScreen/NewBigBankScreen/components/NewBankCenterTopVue.vue";
import dpMap from "./components/bigScreenMap.vue";
@Component({
    components: {
        NewBigScreenTitleVue,
        BigScreenNavVue,
        dpMap,
        NewBankLeftTwoVue,
        NewBankRightTwoVue,
        NewBankLeftOneVue,
        NewBankRightoneVue,
        NewBankCenterTopVue,
    },
})
export default class BigBankScreen extends Vue {
    //变量

    private showBankSelection: boolean = false; //是否展示选择银行
    private changeName: string = ""; //显示的银行名字
    private NavLeftTree: any[] = []; //左侧银行树形结构
    private LeftEchartsData: any[] = []; //左侧第二个图表
    private bankId: string = "";
    private totalLoanCount: number = 0;
    private statisticsObjCount: Object = {};

    //地图变量
    private authorityAdcode: number | null = null; //当前账户的地图权限
    private authorityName: string | null = null; // 当前账户权限对应的名称
    private curentAdcode: number = 0; // 当前区域的 adcode
    private ShowMap: boolean = false;
    private mapHightData: any[] = [];

    //图标变量
    private LeftOneDataList: any[] | null = null;

    private refreshData() {
        //获取图表的全部数据
        let timer: any = setTimeout(() => {
            clearTimeout(timer);
            if (this.bankId) {
                this.getChartsData(this.bankId);
                this.banktotalLoan(this.bankId);
            }
        }, 500);
    }

    //事件处理

    //emit
    private HandlerSelectBankName(item: any) {
        //左侧选择银行emit事件
        // 根据点击地区节点的ID 去匹配高德地区对应的ID，展开地图
        this.changeName = item.bankName; //进入页面请求接口的第一个数据
        this.showBankSelection = false; //关闭选择
        this.bankId = item.bankId;
        let id = item.gdGroupCode;
        if (item.id && item.id.toString().length === 6) {
            // console.log('表明我点击的是最后一层级，六位地区码');
            // 这个code地图是获取不到下一级区域的，所以无法获取地图节点对象，需要我们自己设置名称和点位信息
            (this.$refs.dpMap as any).setLastLevelObj(
                item.name,
                item.longitude,
                item.latitude
            );
        }
        for (let i = Number(id).toString().length; i < 6; i += 1) {
            id += "0";
        }
        if (id === "000000") id = 100000;
        // console.log('补足id为六位，末尾加0：', id);
        // 点击了树结构，需要同步地图变化

        // this.$refs.dpMap.switch2AreaNode(Number(id), null, id);

        // console.log(this, Boolean(this.$refs.dpMap));
        let that = this;

        self();
        function self() {
            // console.log(Boolean(that), 666);
            if (that && that.$refs && that.$refs.dpMap) {
                // console.log('我成功的调用了地图组件', id);
                (that.$refs.dpMap as any).switch2AreaNode(Number(id), null, id);
            } else {
                setTimeout(() => {
                    // console.log('地图还没有加载成功，所以我要一直调用');
                    self();
                }, 100);
            }
        }

        this.getChartsData(item.bankId); //更新四个图标的数据
        this.banktotalLoan(item.bankId); //获取贷款数  猪牛羊
        let dataItem: any = item;
        this.bankmapStatistics(item.bankId); //地图打点
        this.getBackMap(item.bankId).then((res: any) => {
            //请求地图背景地址数据
            const data = res.data;
            if (data.id == 0) {
                this.authorityAdcode = data.id;
                this.authorityName = data.name;
            }
            this.ShowMap = true;

            // let that = this;
            // setTimeout(() => {
            //     // console.log('-----初始化地图数据');
            //     that.HandlerSelectBankName({
            //         gdGroupCode: data.id,
            //         name: data.name,
            //         longitude: data.longitude,
            //         latitude: data.latitude,
            //         id: data.id,
            //         bankId:dataItem.bankId,
            //         bankName:dataItem.bankName
            //     });

            // }, 3000);
        });
    }

    //地图emit
    private setCurentAdcode(adcode: number) {
        // 子组件更新地区adcode  adcode --> bankid
        // console.log('地图组件更新了当前区域，所以我需要设置当前区域的数据', adcode);
        this.curentAdcode = adcode;
        // console.log('-------refreshdata', this.curentAdcode)
        this.refreshData(); //地图发生改变时  组件的图表也同时进行获取对应的新数据
    }
    private setCurentAreaName(name: string): void {
        // 子组件更新地区名称
        // alert(name)
        // this.changeName = name;
    }

    //接口请求
    private getBackMap(bankId: string) {
        //获取地图背景
        return new Promise((resolve: Function) => {
            bigbankMap315(bankId, (data: any) => {
                resolve(data);
            });
        });
    }

    created() {
        bigbankList315((data: any) => {
            //左侧银行树形结构接口
            addOpenUp(data.data); //添加一个属性
            function addOpenUp(data: any) {
                //添加一个属性
                data.forEach((item: any) => {
                    item.openUp = false;
                    if (item.childList.length) {
                        addOpenUp(item.childList);
                    }
                });
            }
            this.NavLeftTree = data.data;
            this.changeName = data.data[0].bankName; //进入页面请求接口的第一个数据
            this.bankId = data.data[0].bankId; //将bankid 存为变量
            this.getChartsData(data.data[0].bankId); //获取图表数据
            this.banktotalLoan(data.data[0].bankId); //获取贷款数  猪牛羊
            let dataItem: any = data.data[0];
            this.bankmapStatistics(data.data[0].bankId); //地图打点
            this.getBackMap(data.data[0].bankId).then((res: any) => {
                //请求地图背景地址数据
                const data = res.data;
                console.log(data);
                if (data.id == 0) {
                    this.authorityAdcode = data.id;
                    this.authorityName = data.name;
                }
                this.ShowMap = true;

                let that = this;
                setTimeout(() => {
                    // console.log('-----初始化地图数据');
                    that.HandlerSelectBankName({
                        gdGroupCode: data.id,
                        name: data.name,
                        longitude: data.longitude,
                        latitude: data.latitude,
                        id: data.id,
                        bankId: dataItem.bankId,
                        bankName: dataItem.bankName,
                    });
                }, 3000);
            });
        });
    }

    //接口请求
    private getChartsData(bankId: string) {
        //4个图表的数据
        // @ts-ignore
        this.$refs.LeftOneRef.getEchartData(bankId);
        // @ts-ignore
        this.$refs.LeftTwoRef.getEchartData(bankId);
        // @ts-ignore
        this.$refs.RightOneRef.getEchartData(bankId);
        // @ts-ignore
        this.$refs.RightTwoRef.getEchartData(bankId);
    }
    private banktotalLoan(bankId: string) {
        //中间上面两个接口的数据
        this.totalLoanCount = 0;
        this.statisticsObjCount = {};
        banktotalLoan315(bankId, (data: any) => {
            //贷款发放总额
            this.totalLoanCount = data.data;
            this.$refs.numberCase = document.getElementsByClassName(
                "fields_num"
            )[0];
            if (
                this.$refs.numberCase.textContent == this.totalLoanCount
            ) {
                return;
            }
            let number = this.totalLoanCount;
            let duration = 5000; // 5秒时间
            let intervalTime = 20; // 每次递增间隔时间
            let steps = Math.ceil(duration / intervalTime); // 总步数
            let currentCount = 0;
            let increment = number / steps; // 计算每一步应该增加的基础值

            this.timer = setInterval(() => {
                // 在基础步长的基础上，加入随机性（这里使用 80% 到 120% 的范围）
                let randomFactor = 0.8 + Math.random() * 0.4;
                let stepIncrement = increment * randomFactor;

                currentCount += stepIncrement;

                if (currentCount < number) {
                    this.$refs.numberCase.textContent = Math.floor(
                        currentCount
                    ); // 显示的数字取整
                } else {
                    this.$refs.numberCase.textContent = number; // 到达最终数字
                    clearInterval(this.timer); // 停止计时器
                }
            }, intervalTime);
        });
        statisticsTotal315(bankId, (data: any) => {
            //活体监管数量
            this.statisticsObjCount = data.data;
        });
    }
    private timer: any = null;
    private bankmapStatistics(bankId: string) {
        //地图打点
        bankmapStatistics315(
            bankId,
            (data: any) => {
                //地图打点
                this.mapHightData = data.data;
            },
            (err: any) => {
                console.log(err);
            }
        );
    }
    private beforeDestroy(): void {
        // 清除定时器
        clearInterval(this.timer);
    }
    // mounted(){
    //     this.getChartsData()
    // }
}
