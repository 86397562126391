
























import {Component , Prop , Vue} from 'vue-property-decorator'
import {newBigBanScreenTs} from './newBigBanScreenTs'
import {dateFun} from '@/common/date'
@Component({})
export default class NewBigScreenTitleVue extends Vue{
    @Prop() that:any
    
    private TitleRightDateObj:any = new newBigBanScreenTs().TitleRightDateObj //右侧时间
    
    public goback():void{//点击返回  回到欢迎页面
        this.$router.push('/Welcome');
    }
    
    created(){
        setInterval(()=>{ //顶部右侧时间定时器
            this.TitleRightDateObj.firstTime = new dateFun().returnDate(new Date() , "-")
            this.TitleRightDateObj.lastTime = new dateFun().returnHourMinuteSecend(new Date() , ":")
        },1000)
    }
}
