


































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { createElement } from "highcharts";
@Component({})
export default class NewBankCenterTopVue extends Vue {
    @Prop() LoanAmountNumber: any; //贷款金额
    @Prop() statisticsObjCount: any; //猪牛羊数量
    private frequency = 2;

    @Watch("LoanAmountNumber", { immediate: true })
    private ChangeLoanNmount(newVal: any) {
        //监视传递过来的 值发生改变的时候
        //调用
        // let LoanList:Array<string> = newVal.toString().split("")
        // this.clearDom()
        // for(let i of LoanList){
        //     this.$nextTick(()=>{
        //         this.CreateDiv(i)
        //     })
        // }
    }

    //创建div
    private CreateDiv(number: number | string) {
        let LoanRef: any = this.$refs["LoanAmound"];
        let backImg = require("@/assets/bigScreenGovernmentImg/bank-top.png");
        let dom = createElement("div");
        let largeFrameHeight = LoanRef.offsetHeight;

        if (number != ".") {
            dom.style.width = "2.625vw";
            dom.style.height = "4.666vh";
            dom.style.background = `url(${backImg})`;
            dom.style.backgroundSize = "100% 100%";
            dom.style.fontSize = 35 + "px";
            dom.style.fontWeight = "bold";
            dom.style.color = "rgba(6, 189, 239, 1)";
            dom.style.textAlign = "center";
            dom.style.lineHeight = "4.666vh";
            dom.style.overflow = "hidden";
            dom.style.marginLeft = "0.52vw";
            dom.style.fontFamily = "DIN";
        } else {
            dom.style.width = "0.36vw";
            dom.style.height = "4.666vh";
            dom.style.lineHeight = "4.666vh";
            dom.style.fontSize = 35 + "px";
            dom.style.fontWeight = "bold";
            dom.style.color = "rgba(6, 189, 239, 1)";
            dom.style.marginLeft = "0.52vw";
            dom.style.fontFamily = "DIN";
        }

        dom.className = "jsDom";
        LoanRef.appendChild(dom);
        let sp: any = createElement("span");
        sp.style.display = "block";
        dom.appendChild(sp);
        sp.textContent = number;

        if (number == 0) {
            return;
        }
        if (number == ".") {
            return;
        }
        // else {
        //     this.frontRoll(sp, largeFrameHeight, 0, number);
        // }
    }

    private clearDom() {
        return new Promise((resolve, reject) => {
            let numberCase: any =
                document.getElementsByClassName("LoanAmound")[0];
            let domList = document.getElementsByClassName("jsDom");
            while (document.getElementsByClassName("jsDom").length > 0) {
                // console.log("节点编号:"+ul.firstChild.innerHTML+" / 节点类型:"+ul.firstChild);
                numberCase.removeChild(numberCase.firstChild);
            }
            resolve("");
        });
    }

    // private appDom(nub:any): void {
    //     let numberCaseRef:any = this.$refs['LoanAmound'];
    //     let largeFrameHeight = numberCaseRef.offsetHeight;
    //     let backImg = require("@/assets/bigScreenGovernmentImg/bank-top.png");
    //     let dom = createElement("div");
    //     dom.style.width = "2.625vw";
    //     dom.style.height = "4.666vh";
    //     dom.style.background = `url(${backImg})`;
    //     dom.style.backgroundSize = "100% 100%";
    //     dom.style.fontSize = 48 + "px";
    //     dom.style.fontWeight = "500";
    //     dom.style.color = "rgba(6, 189, 239, 1)";
    //     dom.style.textAlign = "center";
    //     dom.style.lineHeight = largeFrameHeight + "px";
    //     dom.style.overflow = "hidden";
    //     dom.style.marginLeft = ".1rem";
    //     dom.className = "jsDom";
    //     numberCaseRef.appendChild(dom);

    //     let sp = createElement("span");
    //     sp.style.display = "block";
    //     dom.appendChild(sp);
    //     sp.textContent = "0";
    //     if (nub == 0) {
    //     return;
    //     } else {
    //     this.frontRoll(sp, largeFrameHeight, 0, nub);
    //     }
    // }

    // private frontRoll(sp:any, domHeight:any, currentNub:any, nub:any): void {
    //     let alterNub = currentNub + 1;
    //     let a = 0;
    //     let b = (domHeight - domHeight * 0.72) / 2 + domHeight * 0.72;
    //     let time1 = window.setInterval(() => {
    //     a -= 1;
    //     sp.style.transform = `translate(0,${a + "px"})`;
    //     if (Math.abs(a) >= b) {
    //         sp.textContent = alterNub.toString();
    //         sp.style.transform = `translate(0,${b + "px"})`;
    //         if (alterNub == nub) {
    //         this.rearRoll(sp, b, false, domHeight, alterNub, nub);
    //         } else {
    //         this.rearRoll(sp, b, true, domHeight, alterNub, nub);
    //         }

    //         window.clearInterval(time1);
    //     }
    //     }, this.frequency);
    // }

    // private rearRoll(sp:any, currentHeight:any, goOn:any, domHeight:any, currentNub:any, nub:any): void {
    //     let a = currentHeight;

    //     let time2 = window.setInterval(() => {
    //         a -= 1;
    //         sp.style.transform = `translate(0,${a + "px"})`;
    //         if (a <= 0) {
    //             sp.style.transform = `translate(0,0)`;
    //             if (goOn) {
    //             this.frontRoll(sp, domHeight, currentNub, nub);
    //             }
    //             window.clearInterval(time2);
    //         }
    //     }, this.frequency);
    // }
}
