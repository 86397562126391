






import {Component , Watch , Prop , Vue} from 'vue-property-decorator'
import echarts from 'echarts';
import {newBigBanScreenTs} from './newBigBanScreenTs'
import {statisticsMonth315} from '@/api/index'
@Component({})
export default class NewBankRightoneVue extends Vue{
    private option:any = new newBigBanScreenTs().RightEchartsOne

    private initEcharts(ydatalist:any , xdatalist:any){
        this.option.series[0].data = ydatalist
        this.option.xAxis[0].data = xdatalist
        let chartDom:any = document.getElementById('echartsRightOne');
        let myChart:any = echarts.init(chartDom);
        myChart.clear()
        this.option && myChart.setOption(this.option);
        // 页面监控宽度的变化
        window.addEventListener("resize", function () {
            myChart.resize();
        });

    }

    private getEchartData(bankId:string){
        statisticsMonth315(bankId,(data:any)=>{
            let ydatalist:any[] = []
            let xdatalist:any[] = []
            data.data.forEach((item:any) => {
                ydatalist.push(item.amount)
                xdatalist.push(item.month)
            });
            this.initEcharts(ydatalist,xdatalist)
        })
    }
}
