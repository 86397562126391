



























































































import {Component , Prop , Vue} from 'vue-property-decorator'
@Component({})
export default class BigScreenNavVue extends Vue{
    @Prop() TreeData:any
    @Prop() KeyName:any

    private AnimationFun(item:any , keyName:string , e:any){
        item[keyName] = !item[keyName]
        if(item[keyName]){
            e.target.style.transform = "rotate(90deg)"
        }else{
            e.target.style.transform = "rotate(0deg)"
        }
    }

    private handleCity(item:any){
        this.$emit("HandlerSelectBankName",item)
    }

    get getClass(){
        const that:any = this
        return function (item:any){
            if(item.childList.length){
                return true
            }
            // const width:any = ((item.during / 1440) * 100 ) + '%'
            // return `class : ${width}`
        }
    }


}
