export class dateFun{
    //返回日期的时间格式  => 2022-11.11  格式为年月日   dateObj  可以为new Date() \ 2020-10-12 其他格式未测试
    public returnDate(dateObj:any , Spacer:string){
        let newDate:any = new Date(dateObj)

        let year:any = newDate.getFullYear()
        let month:any = (newDate.getMonth() + 1) < 10 ? ('0' + (newDate.getMonth() + 1)) : (newDate.getMonth() + 1)
        let day:any = (newDate.getDate()) < 10 ? ('0' + (newDate.getDate())) : (newDate.getDate())

        return year + Spacer + month + Spacer + day
    }
    //返回日期时间格式 => 2023-12-04 09:00:00/2023-12-04 11:00:00
    public returnAllDate(dateObj:any , Spacer:string){
        let newDate:any = new Date(dateObj)
    
        let year:any = newDate.getFullYear()
        let month:any = (newDate.getMonth() + 1) < 10 ? ('0' + (newDate.getMonth() + 1)) : (newDate.getMonth() + 1)
        let day:any = (newDate.getDate()) < 10 ? ('0' + (newDate.getDate())) : (newDate.getDate())
        let hours:any = newDate.getHours() < 10 ? ('0' + newDate.getHours()) : newDate.getHours();
        let minutes:any = newDate.getMinutes() < 10 ? ('0' + newDate.getMinutes()) : newDate.getMinutes();
        let seconds:any = newDate.getSeconds() < 10 ? ('0' + newDate.getSeconds()) : newDate.getSeconds();
    
        return year + Spacer + month + Spacer + day + ' ' + hours + ':' + minutes + ':' + seconds;
    }
    //返回日期的时间格式  => 2022-11  格式为年-月
    public returnMonthSpacerDate(dateObj:any , Spacer:string){
        let newDate:any = new Date(dateObj)

        let year:any = newDate.getFullYear()
        let month:any = (newDate.getMonth() + 1) < 10 ? ('0' + (newDate.getMonth() + 1)) : (newDate.getMonth() + 1)
        let day:any = (newDate.getDate()) < 10 ? ('0' + (newDate.getDate())) : (newDate.getDate())

        return year + Spacer + month 
    }

    //返回日期的时间格式  => 11.11  格式为年-月
    public returnMonthDaySpacerDate(dateObj:any , Spacer:string){
        let newDate = new Date(dateObj)
        let month:any = (newDate.getMonth() + 1) < 10 ? ('0' + (newDate.getMonth() + 1)) : (newDate.getMonth() + 1)
        let day:any = (newDate.getDate()) < 10 ? ('0' + (newDate.getDate())) : (newDate.getDate())
        return month + Spacer + day
    }

    //返回年月日 格式 2020年10月10日
    public returnYearMonthDayDate(dateObj:any){
        let newDate:any = new Date(dateObj)

        let year:any = newDate.getFullYear()
        let month:any = (newDate.getMonth() + 1) < 10 ? ('0' + (newDate.getMonth() + 1)) : (newDate.getMonth() + 1)
        let day:any = (newDate.getDate()) < 10 ? ('0' + (newDate.getDate())) : (newDate.getDate())

        return year + '年' + month + '月' + day + '日'
    }

    //返回年月 格式 2020年10月
    public returnYearMonthDate(dateObj:any){
        let newDate:any = new Date(dateObj)

        let year:any = newDate.getFullYear()
        let month:any = (newDate.getMonth() + 1) < 10 ? ('0' + (newDate.getMonth() + 1)) : (newDate.getMonth() + 1)

        return year + '年' + month + '月'
    }
    //返回年月 格式 2020年10月
    public returnMonthDayDate(dateObj:any){
        let newDate:any = new Date(dateObj)
        let month:any = (newDate.getMonth() + 1) < 10 ? ('0' + (newDate.getMonth() + 1)) : (newDate.getMonth() + 1)
        let day:any = (newDate.getDate()) < 10 ? ('0' + (newDate.getDate())) : (newDate.getDate())

        return month + '月' + day + '日'
    }

    //返回昨天的时间  
    public returnYesterday(dateObj:any , Spacer:string){
        let newDate:any = new Date(dateObj)
        let year:any = newDate.getFullYear()
        let month:any = (newDate.getMonth() + 1) < 10 ? ('0' + (newDate.getMonth() + 1)) : (newDate.getMonth() + 1)
        let day:any = (newDate.getDate()-1) < 10 ? ('0' + (newDate.getDate()-1)) : (newDate.getDate()-1)
        return year + Spacer + month + Spacer + day 
    }

    //返回时分
    public returnHourMinute(date:any , spacer:string){
        let newDate = new Date(date)
        let hour:any = (newDate.getHours()) < 10 ? ('0' + (newDate.getHours())) : (newDate.getHours())
        let minutes:any = (newDate.getMinutes()) < 10 ? ('0' + (newDate.getMinutes())) : (newDate.getMinutes())
        return hour + spacer + minutes
    }

    //返回时分秒
    public returnHourMinuteSecend(date:any , spacer:string){
        let newDate:any = new Date(date)
        let hour:any = (newDate.getHours()) < 10 ? ('0' + (newDate.getHours())) : (newDate.getHours())
        let minutes:any = (newDate.getMinutes()) < 10 ? ('0' + (newDate.getMinutes())) : (newDate.getMinutes())
        let second:any = (newDate.getSeconds()) < 10 ? ('0' + (newDate.getSeconds())) : (newDate.getSeconds())
        return hour + spacer + minutes + spacer + second
    }

}