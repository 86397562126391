














import {Component , Watch , Prop , Vue} from 'vue-property-decorator'
import echarts from 'echarts';
import {newBigBanScreenTs} from './newBigBanScreenTs'
import {statisticsMortgage315} from '@/api/index'
@Component({})
export default class NewBankLeftTwoVue extends Vue{

    private EchartsData:any[]=[]
    private option:any = new newBigBanScreenTs().LeftEchartsTwo

    private initEcharts(banklist:Array<string> , piglist:Array<string> , beeflist:Array<string> , sheeplist:Array<string>){
        let chartDom:any = document.getElementById('echarts');
        let myChart:any = echarts.init(chartDom);
        myChart.clear()
        this.option.yAxis.data = banklist
        this.option.series[0].data = piglist
        this.option.series[1].data = beeflist
        this.option.series[2].data = sheeplist
        this.option && myChart.setOption(this.option);
        // 页面监控宽度的变化
        window.addEventListener("resize", function () {
            myChart.resize();
        });

    }

    private HandlerData(data:any[]){
        let banklist:Array<string> = []
        let piglist:Array<string> = []
        let beeflist:Array<string> = []
        let sheeplist:Array<string> = []
        data.forEach((item:any)=>{
            banklist.push(item.bankName)
            piglist.push(item.pigCount)
            beeflist.push(item.cowCount)
            sheeplist.push(item.sheepCount)
        })
        let bbdata:any[] = []
        let ppdata:any[] = []
        let bedata:any[] = []
        let ssdata:any[] = []
        bbdata = banklist.splice(this.initPage,this.PageSize)
        ppdata = piglist.splice(this.initPage,this.PageSize)
        bedata = beeflist.splice(this.initPage,this.PageSize)
        ssdata = sheeplist.splice(this.initPage,this.PageSize)
        this.initEcharts(bbdata,ppdata,bedata,ssdata)
    }

    private getEchartData(bankId:string){
        statisticsMortgage315(bankId,(data:any)=>{
            this.EchartsData = data.data
            if(this.EchartsData.length > 10){
                this.NextBtnFlag = true
            }else{
                this.NextBtnFlag = false
            }
            this.HandlerData(this.EchartsData)
        })
    }


    private initPage: number = 0  //第一次从0开始  从下标0  开始截取  每次截取10个  
    private PageSize: number = 10;  // 每次翻页 的数量
    //关于 翻页

    private NextPage(){
        //先判断 是否是最后一页 如果是最后一页 return
        if( (this.initPage + 10) >= this.EchartsData.length ){  //判断+10 是否 大于 等于 数组的最大长度
            //走另外一个函数
            return
        }else{
            this.initPage += 10
            this.HandlerData(this.EchartsData)
        }
    }

    private PrePage(){
        //先判断
        if((this.initPage - 10) <= 0){
            this.initPage = 0
            this.HandlerData(this.EchartsData)
            return
        }else{
            this.initPage -= 10
            this.HandlerData(this.EchartsData)
        }
        
    }

    private NextBtnFlag:boolean = true
    @Watch("initPage")
    private ChangeInitPage(newVal:any){
        newVal + 10 >= this.EchartsData.length ? this.NextBtnFlag = false : this.NextBtnFlag = true
    }
}
