export const enmuGroupCode = [
    {
        id:0,
        gid:100000
    },
    {
        id:50,
        gid:500000
    },
    {
        id:1310,
        gid:131000
    },
    {
        id:1410,
        gid:141000
    },
    {
        id:2110,
        gid:211000
    },
    {
        id:2310,
        gid:231000
    },
    {
        id:3210,
        gid:321000
    },
    {
        id:3310,
        gid:331000
    },
    {
        id:3410,
        gid:341000
    },
    {
        id:3610,
        gid:361000
    },
    {
        id:3710,
        gid:371000
    },
    {
        id:4110,
        gid:411000
    },
    {
        id:4210,
        gid:421000
    },
    {
        id:4310,
        gid:431000
    },
    {
        id:4420,
        gid:442000
    },
    {
        id:4510,
        gid:451000
    },
    {
        id:5110,
        gid:511000
    },
    {
        id:5120,
        gid:512000
    },
    {
        id:6110,
        gid:611000
    },
    {
        id:6210,
        gid:621000
    },
    {
        id:6230,
        gid:623000
    },
    {
        id:6530,
        gid:653000
    },
    {
        id:6540,
        gid:654000
    },
    {
        id:120110,
        gid:120110
    },
    {
        id:130110,
        gid:130110
    },
    {
        id:130130,
        gid:130130
    },
    {
        id:130430,
        gid:130430
    },
    {
        id:130530,
        gid:130530
    },
    {
        id:130630,
        gid:130630
    },
    {
        id:130730,
        gid:130730
    },
    {
        id:130930,
        gid:130930
    },
    {
        id:140110,
        gid:140110
    },
    {
        id:140430,
        gid:140430
    },
    {
        id:140830,
        gid:140830
    },
    {
        id:140930,
        gid:140930
    },
    {
        id:141030,
        gid:141030
    },
    {
        id:141130,
        gid:141130
    },
    {
        id:150430,
        gid:150430
    },
    {
        id:152530,
        gid:152530
    },
    {
        id:230110,
        gid:230110
    },
    {
        id:230230,
        gid:230230
    },
    {
        id:310110,
        gid:310110
    },
    {
        id:310120,
        gid:310120
    },
    {
        id:320830,
        gid:320830
    },
    {
        id:330110,
        gid:330110
    },
    {
        id:350430,
        gid:350430
    },
    {
        id:360430,
        gid:360430
    },
    {
        id:360730,
        gid:360730
    },
    {
        id:360830,
        gid:360830
    },
    {
        id:361030,
        gid:361030
    },
    {
        id:361130,
        gid:361130
    },
    {
        id:370830,
        gid:370830
    },
    {
        id:411330,
        gid:411330
    },
    {
        id:431230,
        gid:431230
    },
    {
        id:433130,
        gid:433130
    },
    {
        id:440310,
        gid:440310
    },
    {
        id:450110,
        gid:450110
    },
    {
        id:450330,
        gid:450330
    },
    {
        id:451030,
        gid:451030
    },
    {
        id:469030,
        gid:469030
    },
    {
        id:500110,
        gid:500110
    },
    {
        id:500120,
        gid:500120
    },
    {
        id:500230,
        gid:500230
    },
    {
        id:500240,
        gid:500240
    },
    {
        id:513230,
        gid:513230
    },
    {
        id:513330,
        gid:513330
    },
    {
        id:513430,
        gid:513430
    },
    {
        id:520330,
        gid:520330
    },
    {
        id:522630,
        gid:522630
    },
    {
        id:522730,
        gid:522730
    },
    {
        id:532530,
        gid:532530
    },
    {
        id:532930,
        gid:532930
    },
    {
        id:540230,
        gid:540230
    },
    {
        id:540330,
        gid:540330
    },
    {
        id:540530,
        gid:540530
    },
    {
        id:540630,
        gid:540630
    },
    {
        id:610330,
        gid:610330
    },
    {
        id:610430,
        gid:610430
    },
    {
        id:610630,
        gid:610630
    },
    {
        id:610730,
        gid:610730
    },
    {
        id:610830,
        gid:610830
    }
]

interface enmuGroupCodeInterface{
    id:number
    gid:number
}
export const ReturnCodeFlag = (arr:enmuGroupCodeInterface[] , adcode:number)=>{
    for(let i=0; i<arr.length;i++ ){
        if(arr[i].gid === adcode){
          return {
            id:arr[i].id,
            flag:true
          }
        }else{
            if(i === arr.length-1){
                return {
                    id:'',
                    flag:false
                  }
            }
        }
    }
}